const ENV = {
  PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
  MODE: import.meta.env.VITE_MODE,
  BASE_URL: import.meta.env.VITE_BASE_URL,
  VK_APIKEY: import.meta.env.VITE_VK_APIKEY,
  VK_MAPS_TOKEN: import.meta.env.VITE_VK_MAPS_TOKEN,
  METRIC_ID: import.meta.env.VITE_METRIC_ID,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
}

export { ENV }
